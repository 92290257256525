import React, { Component } from "react";
import Cleave from "cleave.js/react";

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface State {
  valid: boolean;
}

export default class PhoneInputBox extends Component<Props> {
  state: State;
  constructor(props: Props) {
    super(props);
    this.state = {
      valid: true
    };
  }

  validate = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ valid: e.currentTarget.value.length === 14 });

  render() {
    const { value, onChange } = this.props;
    const { valid } = this.state;

    const className: string = "form-control" + (valid ? "" : " is-invalid");

    return (
      <Cleave
        options={{
          numericOnly: true,
          blocks: [0, 3, 0, 3, 4],
          delimiters: ["(", ")", " ", "-"]
        }}
        className={className}
        id="inputPhone"
        placeholder="(123) 456-7890"
        name="phone"
        value={value}
        onChange={e => {
          this.validate(e);
          onChange(e);
        }}
        required
      />
    );
  }
}
