import React, { Component } from "react";
import { MembershipFormParams, Role, Gender, MaritalStatus } from "./types";
import DOBInputBox from "../DOBInputBox";
import MembershipFormFirstScreen from "./MembershipFormFirstScreen";
import MembershipFormThirdScreen from "./MembershipFormThirdScreen";
import PhoneInputBox from "../PhoneInputBox";
import axios from "axios";
import MembershipForm from "./MembershipForm";
import FormHelper from "./FormHelper";
import SubmitButton from "../SubmitButton";
import Translation from "../../i18n/Translation";

interface Props {
  firstScreenParams: MembershipFormParams;
  secondScreenParams?: MembershipFormParams;
  thirdScreenParams?: MembershipFormParams[];
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  hasChildren: boolean;
}

interface State {
  person: MembershipFormParams;
  thirdScreen: boolean;
  previousScreen: boolean;
  showSubmitConfirmationScreen: boolean;
  showSpinner: boolean;
}

export default class MembershipFormSecondScreen extends Component<
  Props,
  State
> {
  state: State;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;

  constructor(props: Props) {
    super(props);
    const { firstScreenParams, secondScreenParams } = this.props;
    this.state = {
      person: secondScreenParams
        ? secondScreenParams
        : {
            firstName: "",
            lastName: firstScreenParams.lastName,
            email: "",
            birthdate: "",
            streetAddress: firstScreenParams.streetAddress,
            city: firstScreenParams.city,
            state: firstScreenParams.state,
            zip: firstScreenParams.zip,
            roleId:
              firstScreenParams.roleId === Role["Head of Household"]
                ? Role.Spouse
                : Role["Head of Household"],
            genderId:
              firstScreenParams.genderId === Gender.Male
                ? Gender.Female
                : Gender.Male,
            maritalStatusId: MaritalStatus.Married,
            phone: ""
          },
      thirdScreen: false,
      previousScreen: false,
      showSubmitConfirmationScreen: false,
      showSpinner: false
    };
    this.handleChange = this.props.handleChange.bind(this);
  }

  goBack = () => {
    this.setState({ previousScreen: true });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { firstScreenParams, hasChildren } = this.props;
    const { person } = this.state;
    const family = [firstScreenParams, person];

    if (!FormHelper.validatePerson(person)) {
      FormHelper.invalidFormDataAlert();
      return;
    }

    if (hasChildren) {
      this.setState({ thirdScreen: true });
    } else {
      this.setState({ showSpinner: true });
      (await FormHelper.submitData(
        axios({
          method: "post",
          url: "/.netlify/functions/add-family",
          headers: { "Content-Type": "application/json" },
          data: family
        })
      ))
        ? this.setState({ showSubmitConfirmationScreen: true })
        : this.setState({ showSpinner: false });
    }
  };

  render() {
    const {
      person,
      thirdScreen,
      previousScreen,
      showSubmitConfirmationScreen,
      showSpinner
    } = this.state;
    const {
      firstScreenParams,
      thirdScreenParams,
      handleChange,
      hasChildren
    } = this.props;
    const t = new Translation().get;
    if (thirdScreen) {
      return (
        <MembershipFormThirdScreen
          firstScreenParams={firstScreenParams}
          secondScreenParams={person}
          thirdScreenParams={thirdScreenParams}
          handleChange={handleChange}
          hasChildren={hasChildren}
        />
      );
    } else if (previousScreen) {
      return (
        <MembershipFormFirstScreen
          firstScreenParams={firstScreenParams}
          secondScreenParams={person}
          handleChange={handleChange}
        />
      );
    } else if (showSubmitConfirmationScreen) {
      return <MembershipForm />;
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <input type="hidden" name="form-name" value="membership" />
        <div className="form-row justify-content-between mb-2">
          <h3>Spouse</h3>

          <button
            className="btn btn-secondary"
            aria-label="Back"
            type="button"
            onClick={this.goBack}
          >
            Back
          </button>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="inputFirstName">{t("first_name")}</label>
            <input
              type="text"
              className="form-control"
              id="inputFirstName"
              placeholder="First name"
              value={person.firstName}
              name="firstName"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputLastName">{t("last_name")}</label>
            <input
              type="text"
              className="form-control"
              id="inputLastName"
              placeholder="Last name"
              name="lastName"
              value={person.lastName}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail">{t("email")}</label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Email"
              name="email"
              value={person.email}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputPhone">{t("phone")}</label>
            <PhoneInputBox value={person.phone} onChange={this.handleChange} />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="inputBirthdate">{t("date_of_birth")}</label>
            <DOBInputBox
              value={person.birthdate}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <SubmitButton
          disabled={false}
          text={hasChildren ? "Next" : "Submit"}
          showSpinner={showSpinner}
        />
      </form>
    );
  }
}
