import React, { Component } from "react";
import { GRADES } from "./types";

interface Props {
  value: string | undefined;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  graduationYear: number;
}

interface State {
  defaultValue: string;
}

export default class GraduationYearSelect extends Component<Props, State> {
  state: State;

  constructor(props: Props) {
    super(props);

    this.state = {
      defaultValue: ""
    };
  }

  getOptions = () => {
    const { graduationYear } = this.props;

    const options = GRADES.map((label, i) => (
      <option key={i} value={(graduationYear - i).toString()}>
        {label}
      </option>
    ));
    options.push(
      <option key={GRADES.length} value="0000">
        Graduated
      </option>
    );

    return options;
  };

  render() {
    const { value, onChange } = this.props;
    return (
      <select
        id="inputGraduationYear"
        className="form-control custom-select"
        name="graduationYear"
        value={value}
        onChange={onChange}
      >
        {this.getOptions()}
      </select>
    );
  }
}
