import React, { Component } from "react";
import DOBInputBox from "../DOBInputBox";
import GraduationYearSelect from "../GraduationYearSelect";
import { MembershipFormParams, Role, Gender, MaritalStatus } from "./types";
import PhoneInputBox from "../PhoneInputBox";
import axios from "axios";
import MembershipForm from "./MembershipForm";
import FormHelper from "./FormHelper";
import MembershipFormSecondScreen from "./MembershipFormSecondScreen";
import SubmitButton from "../SubmitButton";
import Translation from "../../i18n/Translation";

interface Props {
  firstScreenParams: MembershipFormParams;
  secondScreenParams?: MembershipFormParams;
  thirdScreenParams?: MembershipFormParams[];
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  hasChildren: boolean;
}

interface State {
  person: MembershipFormParams;
  children: MembershipFormParams[];
  childCount: number;
  previousScreen: boolean;
  showSubmitConfirmationScreen: boolean;
  showSpinner: boolean;
}

export default class MembershipFormThirdScreen extends Component<Props, State> {
  state: State;
  graduationYear: number;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;

  defaultPersonParams: MembershipFormParams;
  constructor(props: Props) {
    super(props);
    const { firstScreenParams, thirdScreenParams } = this.props;
    const graduationYear = new Date().getFullYear() + 13;
    this.defaultPersonParams = {
      firstName: "",
      lastName: firstScreenParams.lastName,
      email: "",
      birthdate: "",
      streetAddress: firstScreenParams.streetAddress,
      city: firstScreenParams.city,
      state: firstScreenParams.state,
      zip: firstScreenParams.zip,
      roleId: Role.Child,
      genderId: Gender.Male,
      maritalStatusId: MaritalStatus.Single,
      phone: "",
      graduationYear: graduationYear.toString()
    };

    this.state = {
      person: this.defaultPersonParams,
      children: thirdScreenParams
        ? thirdScreenParams
        : [this.defaultPersonParams],
      childCount: 1,
      previousScreen: false,
      showSubmitConfirmationScreen: false,
      showSpinner: false
    };

    this.graduationYear = graduationYear;
    this.handleChange = this.props.handleChange.bind(this);
  }

  goBack = () => {
    this.setState({ previousScreen: true });
  };

  // https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd/7091965#7091965
  getAge = (birthDateString: string) => {
    const today = new Date();
    const birthDate = new Date(birthDateString);
    const month = today.getMonth() - birthDate.getMonth();
    let age = today.getFullYear() - birthDate.getFullYear();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  setRoleIds = (children: MembershipFormParams[]): MembershipFormParams[] => {
    return children.map(person => (
      // set role ID to "Child" if under 18 else "Adult"
      {
        ...person, roleId:
          this.getAge(person.birthdate) < 18 ? Role.Child : Role.Adult
      }
    ))
  };

  setLastChild = () => {
    const { children, person } = this.state;
    children[children.length - 1] = person;
    return children;
  };

  addChild = () => {
    if (!FormHelper.validatePerson(this.state.person)) return;

    const { childCount } = this.state;
    const newChildren = [...this.setLastChild(), this.defaultPersonParams];
    this.setState({
      children: newChildren,
      childCount: childCount + 1,
      person: newChildren[newChildren.length - 1]
    });
  };

  deleteChild = () => {
    const { childCount, children } = this.state;
    if (children.length === 1) return;
    children.pop();
    this.setState({
      children: [...children],
      childCount: childCount - 1,
      person: children[children.length - 1]
    });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const children: MembershipFormParams[] =
      this.setRoleIds(this.setLastChild());
    const { firstScreenParams, secondScreenParams } = this.props;
    const family = secondScreenParams
      ? [firstScreenParams, secondScreenParams, ...children]
      : [firstScreenParams, ...children];

    if (!FormHelper.validatePeople(family)) {
      FormHelper.invalidFormDataAlert();
      return;
    }

    this.setState({ showSpinner: true });
    (await FormHelper.submitData(
      axios({
        method: "post",
        url: "/.netlify/functions/add-family",
        headers: { "Content-Type": "application/json" },
        data: family
      })
    ))
      ? this.setState({ showSubmitConfirmationScreen: true })
      : this.setState({ showSpinner: false });
  };

  render() {
    const {
      firstScreenParams,
      secondScreenParams,
      handleChange,
      hasChildren
    } = this.props;
    const {
      person,
      childCount,
      children,
      previousScreen,
      showSubmitConfirmationScreen,
      showSpinner
    } = this.state;
    const disableButton = !FormHelper.validatePerson(this.state.person);
    const t = new Translation().get;

    if (previousScreen) {
      return (
        <MembershipFormSecondScreen
          firstScreenParams={firstScreenParams}
          secondScreenParams={secondScreenParams}
          thirdScreenParams={children}
          handleChange={handleChange}
          hasChildren={hasChildren}
        />
      );
    } else if (showSubmitConfirmationScreen) {
      return <MembershipForm />;
    }
    return (
      <form onSubmit={this.handleSubmit} noValidate={true}>
        <input type="hidden" name="form-name" value="membership" />
        <div className="form-row justify-content-between mb-2">
          <h3>{`Child ${childCount}`}</h3>

          <div>
            <button
              className="btn btn-secondary mr-4"
              aria-label="Back"
              type="button"
              onClick={this.goBack}
              hidden={true}
            >
              Back
            </button>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="inputFirstName">{t("first_name")}</label>
            <input
              type="text"
              className="form-control"
              id="inputFirstName"
              placeholder="First name"
              value={person.firstName}
              name="firstName"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputLastName">{t("last_name")}</label>
            <input
              type="text"
              className="form-control"
              id="inputLastName"
              placeholder="Last name"
              name="lastName"
              value={person.lastName}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="inputEmail">{t("email")}</label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Email"
              name="email"
              value={person.email}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputPhone">{t("phone")}</label>
            <PhoneInputBox value={person.phone} onChange={this.handleChange} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputBirthdate">{t("date_of_birth")}</label>
            <DOBInputBox
              value={person.birthdate}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputGender">{t("gender")}</label>
            <select
              id="inputGender"
              className="form-control custom-select"
              name="genderId"
              value={person.genderId}
              onChange={this.handleChange}
            >
              {FormHelper.getSelectOptions(Gender)}
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputGraduationYear">{t("grade")}</label>
            <GraduationYearSelect
              graduationYear={this.graduationYear}
              value={person.graduationYear}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="form-row justify-content-between mb-2">
          <SubmitButton
            disabled={disableButton}
            text="Submit"
            showSpinner={showSpinner}
          />
          <div
            className="btn-group mr-2"
            role="group"
            aria-label="Add or Delete child"
          >
            <button
              className="btn btn-success"
              aria-label="Add child"
              type="button"
              onClick={this.addChild}
              disabled={disableButton}
            >
              Add Child
            </button>
            <button
              className="btn btn-danger"
              aria-label="Delete child"
              type="button"
              onClick={this.deleteChild}
              disabled={children.length === 1}
            >
              Delete Child
            </button>
          </div>
        </div>
      </form>
    );
  }
}
