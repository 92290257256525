import { LanguageKey } from "./types";
import { TRANSLATION_DICTIONARY } from "./dictionary";

const LANGUAGES: string = process.env.REACT_APP_LANGUAGES || "en";

export default class Translation {
  languages: LanguageKey[];
  constructor() {
    this.languages = this.getLanguages();
  }

  public get = (translationKey: string): string => {
    if (translationKey in TRANSLATION_DICTIONARY) {
      return this.languages
        .map(
          (lang: LanguageKey) => TRANSLATION_DICTIONARY[translationKey][lang]
        )
        .join(" | ");
    }
    throw Error(`Translation key '${translationKey}' not found.`);
  };

  getLanguages = (): LanguageKey[] => {
    return LANGUAGES.split(",").map((key: string) =>
      key.toLowerCase().trim()
    ) as LanguageKey[];
  };
}
