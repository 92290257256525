import { AxiosPromise } from "axios";
import { MembershipFormParams } from "./types";
import React from "react";

const NUM_RETRIES = 2;
const CONFIRMATION_MESSAGE =
  "Thank you for submitting your information and welcome! God bless!";
const FAILURE_MESSAGE =
  "We're sorry but it looks like there was an error submitting your information. Please contact us for help.";

export default class FormHelper {
  static async submitData(request: AxiosPromise<any>): Promise<boolean> {
    try {
      let resp = await request;

      // retry in case of failure
      for (let i = 0; i < NUM_RETRIES && resp.status !== 200; i++) {
        resp = await request;
      }

      if (resp.status === 200) {
        alert(CONFIRMATION_MESSAGE);
        return true;
      } else {
        alert(FAILURE_MESSAGE);
      }
    } catch (err) {
      console.error(err);
      alert(FAILURE_MESSAGE);
    }
    return false;
  }

  static validatePeople(people: MembershipFormParams[]): boolean {
    let isValid: boolean = true;
    people.forEach(
      (person) => (isValid = isValid && this.validatePerson(person))
    );

    return isValid;
  }

  static validatePerson(person: MembershipFormParams): boolean {
    let isValid: boolean = true;
    Object.values(person).forEach((o) => {
      if (!o) {
        isValid = false;
        return isValid;
      }
    });

    return (
      isValid &&
      person.phone.length === 14 &&
      person.birthdate.length === 10 &&
      person.zip.length === 5
    );
  }

  static getSelectOptions(e: any) {
    return this.getNames(e).map((n, i) => (
      <option key={i} value={e[n]}>
        {n}
      </option>
    ));
  }

  static invalidFormDataAlert() {
    alert(
      "It looks like some of the fields aren't valid. " +
      "Common errors include missing digits on your phone number, zip code, or date of birth."
    );
  }

  private static getNames(e: any) {
    return Object.keys(e).filter((k) => typeof e[k] === "number") as string[];
  }

  private static getValues<T extends number>(e: any) {
    return Object.keys(e)
      .map((k) => e[k])
      .filter((v) => typeof v === "number") as T[];
  }
}
