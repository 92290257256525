import { TranslationDictionary } from "./types";

export const TRANSLATION_DICTIONARY: TranslationDictionary = {
  first_name: { en: "First Name", ar: "الاسم" },
  last_name: { en: "Last Name", ar: "اللقب/ اسم العائلة" },
  email: { en: "Email", ar: "البريد الإلكتروني" },
  phone: { en: "Phone", ar: "رقم التليفون" },
  street_address: { en: "Address", ar: "العنوان" },
  city: { en: "City", ar: "المدينة" },
  state: { en: "State", ar: "الولاية" },
  zip: { en: "Zip", ar: "الرمز البريدي" },
  date_of_birth: { en: "Date of Birth", ar: "تاريخ الميلاد" },
  gender: { en: "Gender", ar: "الجنس" },
  marital_status: { en: "Marital Status", ar: "الحالة الاجتماعية" },
  do_you_have_children: { en: "Do you have children?", ar: "هل عندك أطفال؟" },
  grade: { en: "Grade", ar: "المرحلة الدراسية" },
};
