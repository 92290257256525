import React, { Component } from "react";
import Cleave from "cleave.js/react";

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface State {
  valid: boolean;
}

export default class DOBInputBox extends Component<Props, State> {
  state: State;
  constructor(props: Props) {
    super(props);
    this.state = {
      valid: true
    };
  }

  validate = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ valid: e.currentTarget.value.length === 10 });

  render() {
    const { value, onChange } = this.props;
    const { valid } = this.state;

    const className: string = "form-control" + (valid ? "" : " is-invalid");

    return (
      <div>
        <Cleave
          options={{
            date: true,
            delimiter: "/",
            datePattern: ["m", "d", "Y"]
          }}
          type="text"
          className={className}
          id="inputBirthdate"
          name="birthdate"
          placeholder="mm/dd/yyyy"
          value={value}
          onChange={e => {
            this.validate(e);
            onChange(e);
          }}
          required
        />
        <div className="invalid-feedback">
          Please enter your 6 digit date of birth.
        </div>
      </div>
    );
  }
}
