import React from "react";
import "./App.css";

import "./components/MembershipForm";
import MembershipForm from "./components/MembershipForm";

const App: React.FC = () => {
  return (
    <div className="container" style={{ marginTop: "2vh" }}>
      <MembershipForm />
    </div>
  );
};

export default App;
