import React, { Component } from "react";

interface Props {
  text: string;
  disabled: boolean;
  showSpinner: boolean;
}

export default class SubmitButton extends Component<Props> {
  render() {
    const { text, disabled, showSpinner } = this.props;

    return (
      <button
        type="submit"
        className="btn btn-primary d-flex align-items-center"
        disabled={disabled || showSpinner}
      >
        {text}
        <span
          className="spinner-border spinner-border-sm ml-2"
          role="status"
          aria-hidden="true"
          hidden={!showSpinner}
        ></span>
      </button>
    );
  }
}
