import React, { Component } from "react";
import { MembershipFormParams, MaritalStatus, Gender, Role } from "./types";
import DOBInputBox from "../DOBInputBox";
import MembershipFormSecondScreen from "./MembershipFormSecondScreen";
import PhoneInputBox from "../PhoneInputBox";
import SubmitButton from "../SubmitButton";
import axios from "axios";
import Cleave from "cleave.js/react";
import FormHelper from "./FormHelper";
import MembershipForm from "./MembershipForm";
import MembershipFormThirdScreen from "./MembershipFormThirdScreen";
import Translation from "../../i18n/Translation";

interface Props {
  firstScreenParams?: MembershipFormParams;
  secondScreenParams?: MembershipFormParams;
  thirdScreenParams?: MembershipFormParams[];
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}

interface State {
  person: MembershipFormParams;
  secondScreen: boolean;
  thirdScreen: boolean;
  showSpinner: boolean;
  hasChildren: string;
  botInput: string;
  showSubmitConfirmationScreen: boolean;
}

export default class MembershipFormFirstScreen extends Component<Props, State> {
  state: State;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  constructor(props: Props) {
    super(props);
    const { firstScreenParams } = this.props;
    this.state = {
      person: firstScreenParams
        ? firstScreenParams
        : {
            firstName: "",
            lastName: "",
            email: "",
            birthdate: "",
            streetAddress: "",
            city: "",
            state: "TN",
            zip: "",
            roleId: Role.Adult,
            genderId: Gender.Male,
            maritalStatusId: MaritalStatus.Single,
            phone: ""
          },
      botInput: "",
      hasChildren: "0",
      secondScreen: false,
      thirdScreen: false,
      showSpinner: false,
      showSubmitConfirmationScreen: false
    };

    this.handleChange = this.props.handleChange.bind(this);
  }

  hasChildren = () => this.state.hasChildren === "1";
  isMarried = () => this.state.person.maritalStatusId === MaritalStatus.Married;

  setRoleId = async () => {
    const { person } = this.state;
    if (this.isMarried()) {
      if (person.genderId === Gender.Male) {
        // person is married and male, so set to HoH
        this.setState({
          person: {
            ...person,
            ...{ roleId: Role["Head of Household"] }
          }
        });
      } else {
        // set as spouse
        this.setState({
          person: { ...person, ...{ roleId: Role.Spouse } }
        });
      }
    } else if (this.hasChildren()) {
      // single parent, set to HoH
      this.setState({
        person: {
          ...person,
          ...{ roleId: Role["Head of Household"] }
        }
      });
    }
  };

  handleBotInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ botInput: e.currentTarget.value });

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.botInput) return;

    if (!FormHelper.validatePerson(this.state.person)) {
      FormHelper.invalidFormDataAlert();
      return;
    }

    if (this.isMarried()) {
      await this.setRoleId();
      this.setState({ secondScreen: true });
    } else if (this.hasChildren()) {
      await this.setRoleId();
      this.setState({ thirdScreen: true });
    } else {
      this.setState({ showSpinner: true });
      (await FormHelper.submitData(
        axios({
          method: "post",
          url: "/.netlify/functions/add-person",
          headers: { "Content-Type": "application/json" },
          data: this.state.person
        })
      ))
        ? this.setState({ showSubmitConfirmationScreen: true })
        : this.setState({ showSpinner: false });
    }
  };

  render() {
    const {
      botInput,
      hasChildren,
      person,
      secondScreen,
      thirdScreen,
      showSpinner,
      showSubmitConfirmationScreen
    } = this.state;
    const { secondScreenParams, thirdScreenParams, handleChange } = this.props;
    const t = new Translation().get;

    if (secondScreen) {
      return (
        <MembershipFormSecondScreen
          firstScreenParams={person}
          secondScreenParams={secondScreenParams}
          thirdScreenParams={thirdScreenParams}
          handleChange={handleChange}
          hasChildren={this.hasChildren()}
        />
      );
    } else if (thirdScreen) {
      return (
        <MembershipFormThirdScreen
          firstScreenParams={person}
          secondScreenParams={secondScreenParams}
          thirdScreenParams={thirdScreenParams}
          handleChange={handleChange}
          hasChildren={this.hasChildren()}
        />
      );
    } else if (showSubmitConfirmationScreen) {
      return <MembershipForm />;
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-row">
          <h3>Membership Form</h3>
        </div>
        <input
          type="text"
          id="botInput"
          placeholder=""
          value={botInput}
          name="botInput"
          onChange={this.handleBotInput}
          style={{ display: "none" }}
        />
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="inputFirstName">{t("first_name")}</label>
            <input
              type="text"
              className="form-control"
              id="inputFirstName"
              placeholder="First name"
              value={person.firstName}
              name="firstName"
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputLastName">{t("last_name")}</label>
            <input
              type="text"
              className="form-control"
              id="inputLastName"
              placeholder="Last name"
              name="lastName"
              value={person.lastName}
              onChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="inputEmail4">{t("email")}</label>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="Email"
              name="email"
              value={person.email}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="inputPhone">{t("phone")}</label>
            <PhoneInputBox value={person.phone} onChange={this.handleChange} />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="inputStreetAddress">{t("street_address")}</label>
          <input
            type="text"
            className="form-control"
            id="inputStreetAddress"
            name="streetAddress"
            placeholder="1234 Main St"
            value={person.streetAddress}
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="inputCity">{t("city")}</label>
            <input
              type="text"
              className="form-control"
              id="inputCity"
              name="city"
              value={person.city}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">{t("state")}</label>
            <select
              id="inputState"
              className="form-control custom-select"
              value={person.state}
              onChange={this.handleChange}
              name="state"
            >
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="inputZip">{t("zip")}</label>
            <Cleave
              options={{ numericOnly: true }}
              className="form-control"
              id="inputZip"
              name="zip"
              value={person.zip}
              onChange={this.handleChange}
              minLength={5}
              maxLength={5}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md">
            <label htmlFor="inputBirthdate">{t("date_of_birth")}</label>
            <DOBInputBox
              value={person.birthdate}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="inputGender">{t("gender")}</label>
            <select
              id="inputGender"
              className="form-control custom-select"
              name="genderId"
              value={person.genderId}
              onChange={this.handleChange}
            >
              {FormHelper.getSelectOptions(Gender)}
            </select>
          </div>
          <div className="form-group col-md">
            <label htmlFor="inputMaritalStatus">{t("marital_status")}</label>
            <select
              id="inputMaritalStatus"
              className="form-control custom-select"
              name="maritalStatusId"
              value={person.maritalStatusId}
              onChange={this.handleChange}
            >
              {FormHelper.getSelectOptions(MaritalStatus)}
            </select>
          </div>
          <div className="form-group col-md">
            <label htmlFor="inputHasChildren">{t("do_you_have_children")}</label>
            <select
              id="inputHasChildren"
              className="form-control custom-select"
              name="hasChildren"
              value={hasChildren}
              onChange={hasChildren =>
                this.setState({
                  hasChildren: hasChildren.currentTarget.value
                })
              }
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
          </div>
        </div>
        <SubmitButton
          disabled={false}
          text={this.isMarried() || this.hasChildren() ? "Next" : "Submit"}
          showSpinner={showSpinner}
        />
      </form>
    );
  }
}
