import React, { Component } from "react";
import MembershipFormFirstScreen from "./MembershipFormFirstScreen";
import { MembershipFormParams } from "./types";

interface Props {}

interface State {
  person: MembershipFormParams;
}

export default class MembershipForm extends Component<Props, State> {
  handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { person } = this.state;
    const key: string = e.currentTarget.name;
    const isNumericId = ["roleId", "genderId", "maritalStatusId"].includes(key);
    const value: string | number = isNumericId
      ? Number(e.currentTarget.value)
      : e.currentTarget.value;
    if (Object.keys(person).includes(key)) {
      this.setState({ person: { ...person, ...{ [key]: value } } });
    }
  }
  render() {
    return <MembershipFormFirstScreen handleChange={this.handleChange} />;
  }
}
