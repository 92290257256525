export interface MembershipFormParams {
  firstName: string;
  lastName: string;
  email: string;
  birthdate: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  roleId: number;
  genderId: number;
  maritalStatusId: number;
  graduationYear?: string;
  phone: string;
}

export enum MaritalStatus {
  Single = 1,
  Married = 3,
  Divorced = 4,
  Separated = 6
}

export enum Gender {
  Male = 1,
  Female
}

export enum Role {
  Child = 2,
  Adult,
  "Head of Household",
  Spouse
}
